import { Grid } from '@audentio/stuff/src';
import styled from '@emotion/styled';
import { Box } from 'components/Box';
import { Layout } from 'components/Layout';
import { PrincipleBenefits } from 'components/Principle/Benefits';
import { FeatureHighlight } from 'components/Principle/FeatureHighlight';
import { PrincipleFeatures } from 'components/Principle/Features';
import { PrinciplesNav } from 'components/PrinciplesNav';
import { graphql } from 'gatsby';
import React from 'react';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import {
    ClientHighlight,
    StatBox,
} from 'src/components/Methods/metrics/ClientHighlight';
import { PrincipleHero } from 'src/components/Principle/Hero';

const Underline = styled(Box)`
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 4px;
    left: 0;
`;

const ColoredHeadingText = ({ color, children, ...props }) => (
    <Box as="span" position="relative" color={color} {...props}>
        <Underline backgroundColor={color} as="span" />
        {children}
    </Box>
);

const FeaturesTitle = () => (
    <>
        gamify your community with{' '}
        <ColoredHeadingText color="error.500">rewards</ColoredHeadingText>,
        <Box as="br" display={{ _: 'none', lg: 'block' }} />{' '}
        <ColoredHeadingText color="info.500">gaming events</ColoredHeadingText>,
        and{' '}
        <ColoredHeadingText color="warning.500">competition</ColoredHeadingText>
    </>
);

const GamificationPage = ({ data }) => {
    return (
        <Layout
            pageTitleFull="Gamification: An Audentio Community Principle"
            siteDescription="Gamify your online community with rewards, gaming events, and competition. Let’s make your gamification a success!"
            contactForm
            subnavTitle="Gamification"
            subnav={[
                {
                    id: 'features',
                    label: 'Features',
                },
                {
                    id: 'leaderboard',
                    label: 'Leaderboard',
                },
                {
                    id: 'benefits',
                    label: 'Benefits',
                },
                {
                    id: 'events',
                    label: 'Events',
                },
            ]}
        >
            <PrincipleHero
                title="Drive activity and reward members for their accomplishments"
                subtitle="gamification"
                image={data.hero.childImageSharp.fluid}
            />

            <PrinciplesNav name="gamification" />

            <PrincipleFeatures
                id="features"
                title="gamify your community with rewards, gaming events, and competition"
                features={[
                    {
                        subtitle: 'rewards',
                        title: 'award badges for milestones',
                        content:
                            'Make members feel accomplished for their efforts and incentivized to reach the next goal with unique branded badge icons.',
                    },
                    {
                        subtitle: 'gaming',
                        title: 'host video game events',
                        content:
                            'Bring like-minded members together in a new way in short-term or long-term events.',
                    },
                    {
                        subtitle: 'competitive',
                        title: 'gain experience points to level up',
                        content:
                            'Visually show progress to regular members for their continued activity.',
                    },
                    {
                        subtitle: 'rewards',
                        title: 'open a reward shop',
                        content:
                            'Offer branded swag, animated profile backgrounds, and more.',
                    },
                    {
                        subtitle: 'gaming',
                        title: 'start a streaming schedule',
                        content:
                            'Broadcasting live can introduce a new revenue stream while building an inclusive community. ',
                    },
                    {
                        subtitle: 'competitive',
                        title: 'rank up in a leaderboard',
                        content:
                            'A community-wide leaderboard can highlight members for their high-level of activity.',
                    },
                ]}
            />

            {/* <CTA title="Ready to build a community?" /> */}

            <FeatureHighlight
                bg="secondaryBackground"
                id="leaderboard"
                py={['40px', 'spacing-xl', '80px']}
                my={0}
                highlights={[
                    {
                        title: 'support for seasons',
                        content: 'Have scores reset after a specified time.',
                    },
                    {
                        title: 'award top placements',
                        content:
                            'Award unique badges per season to top members.',
                    },
                    {
                        title: 'boost engagement with friendly competition',
                        content:
                            'People are goal-oriented and enjoy seeing their engagement earn them recognition.',
                    },
                ]}
                title="See how members stack against each other with a leaderboard"
                subtitle="competitive"
                image={data.leaderboard.childImageSharp.fluid}
            />

            <ClientHighlight
                title="Synner Community"
                logo={data.synnerLogo.childImageSharp.fixed}
                image={data.synner.childImageSharp.fluid}
                bg="primaryBackground"
                description="Introducing a donation campaign, membership model, leaderboard, and custom achievements were all a part of our strategy to help this school and community for guitarists."
                pt={[0, 0, 0]}
                alt="Graph showing the quantity of posts created and the trend up once gamification was introduced for Synner community."
                gridSectionProps={{ borderTop: '0px' }}
            >
                <Grid
                    columns={{
                        _: 2,
                        lg: 1,
                    }}
                    spacing={{
                        _: 'spacing-lg',
                        lg: 'spacing-xl',
                    }}
                    w={{
                        _: '100%',
                        lg: 250,
                    }}
                >
                    <StatBox
                        title="115%"
                        subtitle="Increase in"
                        kind="Number of posts made"
                    />
                    <StatBox
                        title="30%"
                        subtitle="Increase in"
                        kind="Number of threads made"
                    />
                </Grid>
            </ClientHighlight>

            <PrincipleBenefits
                id="benefits"
                mt={0}
                title="How gamification can benefit your community"
                // ctaHref="/contact?source=gamification"
                // ctaLabel="contact us"
                benefits={[
                    'awards users for valuable activities',
                    'give more reasons for users to keep coming back',
                    'boost activity across the board',
                ]}
            />

            {/* <MediaSection
                id="events"
                image={data.videogames.childImageSharp.fluid}
                title="Video game events can be for everyone, and we can help with that"
            >
                It doesn’t matter if your community isn’t gaming-focused; any
                community can benefit from an event like this. By doing so, you
                can bring in another revenue stream and build long-term
                engagement with members.
            </MediaSection> */}

            <LandingFeatureGrid
                center
                offset={false}
                flexDirection="column"
                title="Related articles"
                sectionHeaderProps={{
                    direction: 'row',
                }}
                customButton={{
                    text: 'See More',
                    href: 'https://adjourn.audent.io/',
                }}
                showContact={false}
                items={[
                    {
                        image: data.activeCommunity.childImageSharp.fluid,
                        heading:
                            'Building an active community through incentivization and gamification',
                        content:
                            'Building a community requires a significant investment, and not just from you. As a community manager, you’re asking people to join, share...',
                        href:
                            'https://adjourn.audent.io/building-an-active-community-through-incentivization-and-gamification-eb0551232b22',
                    },
                    {
                        image: data.housecup.childImageSharp.fluid,
                        heading:
                            'Helping with community culture on Slack and Discord',
                        content:
                            'We built a Slack and Discord bot to help boost camaraderie and more than a year later, we take a look at how it’s been received...',
                        href:
                            'https://adjourn.audent.io/helping-with-community-culture-on-slack-and-discord-5fa6941f69e9',
                    },
                    {
                        image: data.improvedUX.childImageSharp.fluid,
                        heading:
                            'How we improved the forum user experience by learning from social media',
                        content:
                            'Online communities, specifically forums, are struggling to keep up with the modern user experience that large social media platforms...',
                        href:
                            'https://adjourn.audent.io/how-we-can-improve-the-forum-user-experience-41e4e036a27f',
                    },
                ]}
            />
        </Layout>
    );
};

export default GamificationPage;

export const query = graphql`
    query GamificationQuery {
        hero: file(relativePath: { eq: "images/hero/gamification.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        leaderboard: file(
            relativePath: { eq: "images/gamification/leaderboard.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        videogames: file(
            relativePath: { eq: "images/gamification/gaming-events.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        improvedUX: file(
            relativePath: { eq: "images/gamification/improved-ux.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        housecup: file(
            relativePath: { eq: "images/gamification/housecup.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        activeCommunity: file(
            relativePath: { eq: "images/gamification/active-community.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        synner: file(
            relativePath: { eq: "images/metrics/synner-highlight.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        synnerLogo: file(
            relativePath: { eq: "images/clients/synner-logo.png" }
        ) {
            publicURL
            childImageSharp {
                fixed(height: 120) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
`;
